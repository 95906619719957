<template>
  <div class="merchant">
    <ShopNavBar title="联盟商家" />

    <div class="container">
      <div class="cell" @click="tolink('goodsList')">
        <div class="row items-center">
          <img src="@/assets/img/shop/merchant-shop.png" width="30" class="q-mr-sm" />
          <div>商品管理</div>
        </div>
        <q-icon name="arrow_forward_ios"></q-icon>
      </div>
      <div class="cell" @click="tolink('orderList')">
        <div class="row items-center">
          <img src="@/assets/img/shop/merchant-order.png" width="30" class="q-mr-sm" />
          <div>订单管理</div>
        </div>
        <q-icon name="arrow_forward_ios"></q-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {
      
    }
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style scoped>
.merchant {
  padding: 10px;
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.container {
  background-color: #fff;
}

.cell {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(223, 217, 217, 0.4);
}

.cell:last-child {
  border: none;
}
</style>